exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-[slug]-js": () => import("./../../../src/pages/[slug].js" /* webpackChunkName: "component---src-pages-[slug]-js" */),
  "component---src-pages-detail-[slug]-js": () => import("./../../../src/pages/detail/[slug].js" /* webpackChunkName: "component---src-pages-detail-[slug]-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-search-pihk-detail-[code]-js": () => import("./../../../src/pages/search/pihk/detail/[code].js" /* webpackChunkName: "component---src-pages-search-pihk-detail-[code]-js" */),
  "component---src-pages-search-pihk-index-js": () => import("./../../../src/pages/search/pihk/index.js" /* webpackChunkName: "component---src-pages-search-pihk-index-js" */),
  "component---src-pages-search-ppiu-detail-[code]-js": () => import("./../../../src/pages/search/ppiu/detail/[code].js" /* webpackChunkName: "component---src-pages-search-ppiu-detail-[code]-js" */),
  "component---src-pages-search-ppiu-index-js": () => import("./../../../src/pages/search/ppiu/index.js" /* webpackChunkName: "component---src-pages-search-ppiu-index-js" */),
  "component---src-pages-tag-[slug]-js": () => import("./../../../src/pages/tag/[slug].js" /* webpackChunkName: "component---src-pages-tag-[slug]-js" */),
  "component---src-pages-tag-empty-category-slug-js": () => import("./../../../src/pages/tag/empty/[category_slug].js" /* webpackChunkName: "component---src-pages-tag-empty-category-slug-js" */),
  "component---src-pages-tag-empty-index-js": () => import("./../../../src/pages/tag/empty/index.js" /* webpackChunkName: "component---src-pages-tag-empty-index-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */)
}

